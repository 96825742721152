<div
    class="relative float-left"
    [ngStyle]="{ 'width.px': size, 'height.px': size }">
    <span class="absolute transform -translate-x-1/2 -translate-y-1/2 left-1/2 top-1/2">
        <ng-content />
    </span>

    <svg
        class="absolute w-full h-full -rotate-90"
        width="100"
        height="100"
        viewBox="0 0 100 100"
        aria-hidden="true">
        <circle
            cx="50"
            cy="50"
            [attr.r]="svgRadius"
            [attr.stroke-width]="svgStrokeWidth"
            fill="none"
            class="stroke-gray-300"
            [ngClass]="{
                '!stroke-primary': colorMap.primarySpan && colorMap.primarySpan.max === angle,
                '!stroke-success': colorMap.successSpan && colorMap.successSpan.max === angle,
                '!stroke-warning': colorMap.warningSpan && colorMap.warningSpan.max === angle
            }" />
        <circle
            cx="50"
            cy="50"
            [attr.r]="svgRadius"
            [attr.stroke-width]="svgStrokeWidth"
            pathLength="100"
            fill="none"
            [style.strokeDashoffset]="svgStrokeDashoffset"
            class="[stroke-dasharray:100] transition-[stroke-dashoffset]"
            [ngClass]="{
                'stroke-primary': colorMap.primarySpan && colorMap.primarySpan.min <= angle && colorMap.primarySpan.max >= angle,
                'stroke-success': colorMap.successSpan && colorMap.successSpan.min <= angle && colorMap.successSpan.max >= angle,
                'stroke-warning': colorMap.warningSpan && colorMap.warningSpan.min <= angle && colorMap.warningSpan.max >= angle
            }" />
    </svg>
</div>
